/**
 *       **** Readme ****
 *
 * Component: <FormSelect />
 * Purpose: Returns the Select element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React, { useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//Components
import selectStyles from "../GlobalTools";

export default function FormSelect({ label, ...props }) {
  const [t] = useTranslation(["global"]);
  const [field, meta, helpers] = useField(props);

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <Form.Group className={props.className || "mb-3"}>
      {label && <Form.Label
        htmlFor={props.id || props.name}
        className={props.required && props.showRequired ? "required" : ""}
      >
        {label}
        {!props.required && props.showRequired === false ? (
          <span className="optional">{t("optional")}</span>
        ) : (
          ""
        )}
      </Form.Label>
      }
      <Select
        classNamePrefix={"select"}
        className={
          meta.touched && meta.error ? `select errorValidation` : `select`
        }
        styles={selectStyles}
        {...field}
        {...props}
        onBlur={() => helpers.setTouched(true)}
        noOptionsMessage={() => t("select.noMatches")}
        loadingMessage={() =>  t("select.searching")}
      />
      {meta.touched && meta.error ? (
        <Form.Text className="text-muted error">{meta.error}</Form.Text>
      ) : null}
    </Form.Group>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  className: PropTypes.string,
};
