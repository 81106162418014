import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// API Services
import { ConceptFifoPayments } from "../../../api/Products";

// Import Components
import TextLoading from "../../../components/global/TextLoading";

// Set Custom Styles
const customStyles = {
  categoriesContainer: {
    overflowY: "auto",
    maxHeight: window.innerHeight / 1.5 + "px",
  },
  conceptsContainer: {
    border: "solid 1px #f0f0f0",
  },
};

export default function ModalChronologicalPayments(props) {
  // Hooks
  const [t] = useTranslation(["products", "global"]);
  const [allCategories, setAllCategories] = useState(props.categories);
  const [conceptsForFifoPayments, setConceptsForFifoPayments] = useState([]);
  const [conceptsForDisableFifoPayments, setConceptsForDisableFifoPayments] =
    useState([]);
  const [changesDetected, setChangesDetected] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Description: Check concepts with FIFO
   * @param {object} e
   * @param {number} posCategory
   * @param {number} posConcept
   */
  const checkConcept = (e, posCategory, posConcept) => {
    const clonedCategories = JSON.parse(JSON.stringify(allCategories));
    const updatedConceptsForFifoPayments = [...conceptsForFifoPayments];
    const updatedConceptsForDisableFifoPayments = [
      ...conceptsForDisableFifoPayments,
    ];

    if (posConcept === "all") {
      const allConceptsChecked = e.target.checked;

      for (let item of clonedCategories[posCategory].product_concepts) {
        // Update the `fifo_payment` status for all concepts
        item.fifo_payment = allConceptsChecked;

        if (allConceptsChecked) {
          // Add the concept ID to the FIFO payment list if it's not already there
          if (!updatedConceptsForFifoPayments.includes(item.id)) {
            updatedConceptsForFifoPayments.push(item.id);
          }

          // Remove the concept ID from the disabled list if it's present
          const index = updatedConceptsForDisableFifoPayments.indexOf(item.id);
          if (index > -1) {
            updatedConceptsForDisableFifoPayments.splice(index, 1);
          }
        } else {
          // Remove the concept ID from the FIFO payment list if it's present
          const index = updatedConceptsForFifoPayments.indexOf(item.id);
          if (index > -1) {
            updatedConceptsForFifoPayments.splice(index, 1);
          }

          // Check if the concept was previously active in the backup and add it to the disabled list if true
          const indexBackup = props.categories[
            posCategory
          ].product_concepts.findIndex(
            (backupItem) => backupItem.id === item.id
          );
          if (
            indexBackup > -1 &&
            props.categories[posCategory].product_concepts[indexBackup]
              .fifo_payment == 1
          ) {
            updatedConceptsForDisableFifoPayments.push(item.id);
          }
        }
      }
    } else {
      const conceptSelected =
        clonedCategories[posCategory].product_concepts[posConcept];
      conceptSelected.fifo_payment = !conceptSelected.fifo_payment;

      if (conceptSelected.fifo_payment) {
        // Check if the concept was inactive in the backup and add it to the FIFO payment list
        const indexBackup = props.categories[
          posCategory
        ].product_concepts.findIndex(
          (backupItem) => backupItem.id === conceptSelected.id
        );
        if (
          indexBackup > -1 &&
          props.categories[posCategory].product_concepts[indexBackup]
            .fifo_payment == 0
        ) {
          updatedConceptsForFifoPayments.push(conceptSelected.id);
        }

        // Remove the concept ID from the disabled list if it's present
        const index = updatedConceptsForDisableFifoPayments.indexOf(
          conceptSelected.id
        );
        if (index > -1) {
          updatedConceptsForDisableFifoPayments.splice(index, 1);
        }
      } else {
        // Remove the concept ID from the FIFO payment list if it's present
        const index = updatedConceptsForFifoPayments.indexOf(
          conceptSelected.id
        );
        if (index > -1) {
          updatedConceptsForFifoPayments.splice(index, 1);
        }

        // Check if the concept was active in the backup and add it to the disabled list if true
        const indexBackup = props.categories[
          posCategory
        ].product_concepts.findIndex(
          (backupItem) => backupItem.id === conceptSelected.id
        );
        if (
          indexBackup > -1 &&
          props.categories[posCategory].product_concepts[indexBackup]
            .fifo_payment
        ) {
          updatedConceptsForDisableFifoPayments.push(conceptSelected.id);
        }
      }
    }

    // Update state
    setAllCategories(clonedCategories);
    setConceptsForFifoPayments(updatedConceptsForFifoPayments);
    setConceptsForDisableFifoPayments(updatedConceptsForDisableFifoPayments);
    setChangesDetected(true);
  };

  /**
   * Description: Function to update concepts with FIFO
   */
  const updateFifoPayments = () => {
    if (
      conceptsForFifoPayments.length === 0 &&
      conceptsForDisableFifoPayments.length === 0
    ) {
      props.onHide();
      return;
    }
    const payload = {
      enable_fifo_payment: conceptsForFifoPayments,
      disable_fifo_payment: conceptsForDisableFifoPayments,
    };
    setIsSaving(true);
    ConceptFifoPayments(payload)
      .then((response) => {
        if (response.data) {
          props.updateCategories(allCategories);
          props.onHide();
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>
          {t("ModalChronologicalPayments.title")}
          <span className=" ms-2 fs-6">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip">
                  <p className="mt-1 mb-1">
                    {t("ModalChronologicalPayments.titleTooltip")}
                  </p>
                </Tooltip>
              }
            >
              <span className="bi bi-info-circle-fill" aria-hidden="true" />
            </OverlayTrigger>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={customStyles.categoriesContainer}>
          <Col xs={12}>
            {allCategories.map((category, categoryIndex) => {
              if (category.product_concepts?.length) {
                return (
                  <Row key={categoryIndex} className="mb-3">
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} className="mb-1 fw-bold">
                          {category.name}
                        </Col>
                      </Row>
                      <Row className="me-0 ms-0">
                        <Col xs={12} style={customStyles.conceptsContainer}>
                          <Row className="mt-2 mb-2">
                            <Col xs={12} className="mb-2 fw-bold">
                              <Form.Check
                                id={"selectAllConcept" + categoryIndex}
                                onChange={(e) =>
                                  checkConcept(e, categoryIndex, "all")
                                }
                                checked={category.product_concepts.every(
                                  (concept) => concept.fifo_payment == 1
                                )}
                                label={t(
                                  "ModalChronologicalPayments.allConcepts"
                                )}
                              />
                            </Col>
                            {category.product_concepts.map(
                              (concept, conceptIndex) => {
                                return (
                                  <Col
                                    className="mb-2"
                                    key={conceptIndex}
                                    xs={4}
                                  >
                                    <Form.Check
                                      id={"concept" + conceptIndex}
                                      onChange={(e) =>
                                        checkConcept(
                                          e,
                                          categoryIndex,
                                          conceptIndex
                                        )
                                      }
                                      checked={concept.fifo_payment == 1}
                                      label={concept.name}
                                    />
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }
            })}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide} disabled={isSaving}>
          {t("global:buttons.cancel")}
        </Button>

        <Button
          variant="primary"
          onClick={updateFifoPayments}
          disabled={isSaving || !changesDetected}
        >
          {isSaving ? (
            <TextLoading text={t("global:buttons.saving")} variant="light" />
          ) : (
            t("global:buttons.save")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalChronologicalPayments.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};
