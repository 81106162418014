/**
 *       **** Readme ****
 *
 * Component: <FieldInput />
 * Purpose: Returns the Input element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React, { useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function  FieldInput({ label, capitalize, ...props }) {
  const [t] = useTranslation(["global"]);
  const [field, meta] = useField(props);

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <Form.Group className={props.className || "mb-3"}>
      {label ? (
        <Form.Label
          data-text={t("optional")}
          htmlFor={props.id || props.name}
          className={
            props.required && props.showRequired
              ? "required"
              : !props.required && props.showRequired === false
              ? "optional"
              : ""
          }
        >
          {label}
          {props.complementaryLabel ? (
            <span className="fw-light fst-italic">
              {" (" + props.complementaryLabel + ")"}
            </span>
          ) : null}
        </Form.Label>
      ) : null}

      <Form.Control
        className={
          meta.touched && meta.error
            ? `errorValidation${capitalize ? " capitalize-text" : ""}`
            : `well${capitalize ? " capitalize-text" : ""}`
        }
        type="text"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <Form.Text className="text-muted error">{meta.error}</Form.Text>
      ) : null}
    </Form.Group>
  );
}

FieldInput.propTypes = {
  label: PropTypes.string,
  capitalize: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  className: PropTypes.string,
  complementaryLabel: PropTypes.string,
};
