import { React, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Spinner, Form, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { Form as FormOfFormik, Formik } from "formik";
import * as Yup from "yup";
import FieldInput from "../../global/form/FieldInput";
import { DatePicker } from "../../global/form/DatePicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import FormInputLeftDropdown from "../../global/form/FormInputLeftDropdown";
import FormCheck from "../../global/form/FormCheck";
import FormSelect from "../../global/form/FormSelect";
import Cookies from "js-cookie";
import { MainContext } from "../../../App";

import {
  GetRfcIssuer,
  GenerateSku,
  GetSubconcept,
  SaveSubconcept,
} from "../../../api/Products";

const customStyles = {
  prepaidContainer: {
    marginLeft: "0px",
    marginRight: "0px",
    background: "#fafafa",
    border: "1px solid #D5DADB",
    // borderColor: "#d4d4d4",
    borderRadius: "5px",
    padding: "20px 15px",
    marginBottom: "20px",
  },
  iconPlus: {
    marginLeft: "5px",
    color: "#247048",
    fontSize: "17px",
    cursor: "pointer",
  },
  iconTrash: {
    marginLeft: "5px",
    // color: "#247048",
    fontSize: "17px",
    cursor: "pointer",
    paddingTop: "8px",
  },
};

const base = {
  currency_id: "100",
  name: "",
  sku: "",
  price: "",
  tax_percentage: "",
  early_payment_dates: [
    {
      amount: "",
      end_date: "",
      edited: false,
      bank_account_id: "",
    },
  ],
  penalty_type: "flat_fee",
  penalty: "",
  surcharge_end_date: "",
  surcharge_bank_account_id: "",
  add_IVA_surcharge: false,
  iva_surcharge: "",
  penalty_period: "once",
  description: "",
};
// obtener cookie de idioma
const language = Cookies.get("language") || "es";
const isSpanish = language === "es";

const surchargePeriods = [
  { value: "monthly", label: isSpanish ? "Mensual" : "Monthly" },
  { value: "biweekly", label: isSpanish ? "Catorcenal" : "Biweekly" },
  { value: "weekly", label: isSpanish ? "Semanal" : "Weekly" },
  { value: "daily", label: isSpanish ? "Diario" : "Daily" },
  { value: "once", label: isSpanish ? "Una vez" : "Once" },
];

const prefix = process.env.REACT_APP_PREFIX;

export default function ModalSubconcept(props) {
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["global", "products"]);
  const [loading, setLoading] = useState(false);
  const [subconceptInfo, setSubconceptInfo] = useState(base);
  const [sapIsActive, setSapIsActive] = useState(false);
  const [rfcsIssuerList, setRfcsIssuerList] = useState([]);
  const [typingCallbackName, setTypingCallbackName] = useState(null);
  const [generatingSku, setGeneratingSku] = useState(false);
  const [errors, setErrors] = useState(false);
  const [reload, setReload] = useState(false);
  const [hideIvaField, setHideIvaField] = useState(false);
  const [showIvaSurcharge, setShowIvaSurcharge] = useState(false);

  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  /* Initial Loading */
  useEffect(() => {
    getRfcIssuer();
    if (props.subconcept) {
      getSubconcept(props.subconcept);
    }
  }, []);

  useEffect(() => {
    // Validate if the vat check is shown in normal charge
    const cfdiUseCode = props.conceptSelected.cfdi_use?.cfdi_use_code || false;
    const hasVatActivated = props.conceptSelected.add_IVA_subconcept == "1";
    setHideIvaField(cfdiUseCode == "D10" && !hasVatActivated);

    // Validate if the vat is shown in late charge
    const allowedOrganizations = ["664", "660", "663", "360"];
    const isCurrentOrgAllowed = allowedOrganizations.includes(
      Cookies.get(`cmOrganizationID${prefix}`)
    );
    setShowIvaSurcharge(isCurrentOrgAllowed && cfdiUseCode == "D10");

    // Validate if sap is active
    const services = JSON.parse(localStorage.getItem(`${prefix}Services`));
    const sapIsActive =
      services.SAP && services.SAP.active && permissions.sap_reports?.edit;
    setSapIsActive(sapIsActive);
  }, []);

  const getSubconcept = (subconcept) => {
    const payload = {
      id: subconcept.id,
      product_concept_id: subconcept.product_concept_id,
      school_cycle_id: props.cycleSelected.id,
    };
    setReload(true);
    GetSubconcept(payload).then((response) => {
      if (response.status) {
        let subconcept = response.data;
        subconcept.add_IVA_surcharge =
          subconcept.add_IVA_surcharge == 1 ? true : false;
        subconcept.penalty =
          subconcept.penalty && subconcept.penalty != 0
            ? subconcept.penalty
            : "";
        subconcept.surcharge_end_date = subconcept.surcharge_end_date
          ? // Convert string date to date
            new Date(subconcept.surcharge_end_date.replace(" ", "T"))
          : "";

        if (subconcept.early_payment_dates.length > 0) {
          subconcept.early_payment_dates = subconcept.early_payment_dates.map(
            (earlyPayment) => {
              earlyPayment.end_date = earlyPayment.end_date
                ? // Convert string date to date
                  new Date(earlyPayment.end_date.replace(" ", "T"))
                : "";
              return earlyPayment;
            }
          );
        }
        setSubconceptInfo(subconcept);

        setTimeout(() => {
          setReload(false);
        }, 0);
      }
    });
  };

  const getRfcIssuer = () => {
    const payload = {
      organization_id: Cookies.get(`cmOrganizationID${prefix}`),
    };
    GetRfcIssuer(payload).then((response) => {
      if (response.status) {
        let rfcsIssuer = [];
        for (const RFC of response.data) {
          let rfcObj = {};
          let isOverflow = false;
          let label = "";
          isOverflow = RFC.issuer_name.length > 21;
          label = RFC.issuer_rfc + " - " + RFC.issuer_name.substring(0, 22);
          rfcObj.value = RFC.id;
          rfcObj.label = isOverflow ? label + "..." : label;
          rfcsIssuer.push(rfcObj);
        }
        setRfcsIssuerList(rfcsIssuer);
      }
    });
  };

  const getGenerateCode = (field, value, formik) => {
    setGeneratingSku(true);

    let payload = {
      school_cycle_id: props.cycleSelected.id,
    };
    payload[field] = value;
    GenerateSku(payload).then((response) => {
      if (response.status) {
        formik.setFieldValue("sku", response.data.sku);
      } else {
        setErrors(response);
        setTimeout(() => {
          setErrors(false);
        }, 3000);
      }
      setGeneratingSku(false);
    });
  };

  const disableSaveButton = (values) => {
    let disable = false;

    // verificar que name, price, issuer_rfc_id no esten vacios
    disable =
      values.name == "" || values.price == "" || values.issuer_rfc_id == "";

    return disable;
  };

  const save = (values) => {
    let payload = {
      ...values,
      product_concept_id: props.productConceptId,
      tax_percentage: values.tax_percentage ? values.tax_percentage : "0",
    };

    // Remove objects from early_payment_dates if they do not have at least amount and end_date
    let newEarlyPaymentDates = [];
    values.early_payment_dates.forEach((earlyPayment) => {
      if (earlyPayment.amount !== "" && earlyPayment.end_date !== "") {
        earlyPayment.end_date =
          new Date(earlyPayment.end_date).getTime() / 1000;
        newEarlyPaymentDates.push(earlyPayment);
      }
    });
    payload.early_payment_dates = newEarlyPaymentDates;

    // Remove all empty and null fields from values
    Object.keys(payload).forEach((key) => {
      if (payload[key] === "" || payload[key] === null) {
        delete payload[key];
      }
    });

    // Remove penalty_type and penalty_period if penalty does not exist
    if (!payload.penalty) {
      delete payload.penalty_type;
      delete payload.penalty_period;
    }
    if (payload.surcharge_end_date && payload.surcharge_end_date != "") {
      // Convert date to timestamp
      let date = new Date(payload.surcharge_end_date);
      payload.surcharge_end_date = date.getTime() / 1000;
    }

    setLoading(true);
    SaveSubconcept(payload).then((response) => {
      if (response.status) {
        const newSubconcept = values.id ? false : true;
        updateSubconceptList(response.data, newSubconcept);
      } else {
        setErrors(response);
        setTimeout(() => {
          setErrors(false);
        }, 3000);
      }
      setLoading(false);
    });
  };

  const updateSubconceptList = (data, newSubconcept) => {
    if (newSubconcept) {
      // Insert at the end of the list
      let newSubconcepts = props.subconcepts;
      newSubconcepts.push(data);
      props.setSubconceptSelected(data);
    } else {
      // Find the position of the subconcept in the list
      let subconceptIndex = props.subconcepts.findIndex(
        (subconcept) => subconcept.id === data.id
      );
      // Replace the subitem in the list
      let newSubconcepts = props.subconcepts;
      newSubconcepts[subconceptIndex] = data;
      props.setSubconceptSelected(data);
    }

    // Close modal
    props.onHide();
  };

  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("products:modalSubconcept.subconceptName") +
        " " +
        t("global:isRequired")
    ),
    price: Yup.string()
      .required(
        t("products:modalSubconcept.normalFee") + " " + t("global:isRequired")
      )
      .test(
        "validate-price",
        t("products:modalSubconcept.incorrectValue"),
        (value) => {
          if (value) {
            let regex = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
            return regex.test(value);
          }
        }
      ),
    issuer_rfc_id: Yup.string().required(
      t("products:modalSubconcept.RFCtoBill") + " " + t("global:isRequired")
    ),
    // no required
    sku: Yup.string(),
    tax_percentage: Yup.string().test(
      "validate-iva",
      t("products:modalSubconcept.taxValidateMenssage"),
      (value) => {
        if (value) {
          let taxRegex = new RegExp(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/);
          return taxRegex.test(value) || value === "";
        } else {
          return true;
        }
      }
    ),
    penalty: Yup.string().when("penalty_type", {
      is: "percentage",
      then: Yup.string().matches(
        /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/,
        t("products:modalSubconcept.incorrectValue")
      ),
      otherwise: Yup.string().matches(
        /^[0-9]+(\.[0-9]{1,2})?$/,
        t("products:modalSubconcept.incorrectValue")
      ),
    }),
    // surcharge_end_date validar para que sea requerido cuando penalty sea diferente de vacio
    surcharge_end_date: Yup.string().when("penalty", {
      is: (penaltyValue) => {
        return penaltyValue != undefined && penaltyValue != "";
      },
      then: Yup.string().required(
        t("products:modalSubconcept.deadlineSurchargeIsRequired")
      ),
    }),
    // Prompt payment array
    early_payment_dates: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string().test(
          "amount-required-if-end_date",
          t("products:modalSubconcept.amountPaidIsRequired"),
          function (value) {
            const { end_date } = this.parent;
            return end_date ? !!value : true;
          }
        ),
        end_date: Yup.string().test(
          "end_date-required-if-amount",
          t("products:modalSubconcept.deadlineIsRequired"),
          function (value) {
            const { amount } = this.parent;
            return amount ? !!value : true;
          }
        ),
      })
    ),
  });

  return (
    <Modal
      show={props.showSubconceptModal}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
      onHide={props.onHide}
      backdrop={"static"}
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {props.subconcept
            ? t("products:modalSubconcept.editSubconcept")
            : t("products:modalSubconcept.title")}
        </Modal.Title>
      </Modal.Header>
      {!reload ? (
        <Formik
          initialValues={subconceptInfo}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            save(values);
          }}
        >
          {({ values, ...formik }) => (
            <FormOfFormik>
              <Modal.Body>
                <Row>
                  <Col md={12} className=" mb-2">
                    <FieldInput
                      label={t("products:modalSubconcept.subconceptName")}
                      complementaryLabel={t(
                        "products:modalSubconcept.subconceptNameLabel"
                      )}
                      capitalize={true}
                      name="name"
                      type="text"
                      placeholder={t("products:modalSubconcept.subconceptName")}
                      required={true}
                      showRequired={true}
                      onChange={(event) => {
                        formik.setFieldValue("name", event.target.value);
                        // Generate sku from sub item name
                        if (typingCallbackName) {
                          clearTimeout(typingCallbackName);
                        }
                        let typingName = setTimeout(() => {
                          getGenerateCode("name", event.target.value, formik);
                        }, 500);
                        setTypingCallbackName(typingName);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className=" mb-2">
                    <FieldInput
                      label={t("products:modalSubconcept.sku")}
                      capitalize={true}
                      name="sku"
                      type="text"
                      placeholder={
                        generatingSku
                          ? t("products:modalSubconcept.generatingSku")
                          : "SKU"
                      }
                      onChange={(event) => {
                        formik.setFieldValue("sku", event.target.value);
                        // Generate sku from sub item name
                        if (typingCallbackName) {
                          clearTimeout(typingCallbackName);
                        }
                        let typingName = setTimeout(() => {
                          getGenerateCode("sku", event.target.value, formik);
                        }, 500);
                        setTypingCallbackName(typingName);
                      }}
                      value={values.sku}
                      disabled={generatingSku}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className=" mb-2">
                    <FieldInput
                      label={t("products:modalSubconcept.normalFee")}
                      capitalize={true}
                      name="price"
                      type="text"
                      placeholder="$"
                      required={true}
                      showRequired={true}
                      onKeyPress={(e) => {
                        if (
                          (e.which < 48 || e.which > 57) &&
                          e.which !== 8 &&
                          e.which !== 46 &&
                          e.which !== 44
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  <Col md={6} className={hideIvaField ? "d-none" : ""}>
                    <FormSelect
                      label={t("products:modalSubconcept.iva")}
                      name="tax_percentage"
                      className="mb-0"
                      options={[
                        {
                          value: "",
                          label: "0%",
                        },
                        {
                          value: "8",
                          label: "8 %",
                        },
                        {
                          value: "16",
                          label: "16 %",
                        },
                      ]}
                      onChange={(country) => {
                        formik.setFieldValue("tax_percentage", country.value);
                      }}
                      value={
                        values.tax_percentage && values.tax_percentage !== ""
                          ? {
                              value: values.tax_percentage,
                              label: values.tax_percentage + " %",
                            }
                          : ""
                      }
                      placeholder="0.00 %"
                    />

                    <Form.Text className="text-muted">
                      {t("products:modalSubconcept.ivaLabel")}
                    </Form.Text>
                  </Col>
                </Row>

                {/* Pronto pago */}
                <Row className="mt-3">
                  <Col md={6} className="mb-2">
                    <Form.Label>
                      {t("products:modalSubconcept.prepaid")}
                    </Form.Label>
                    <Form.Text className="text-muted">
                      {" ( " +
                        t("products:modalSubconcept.beforePrepaymentDeadline") +
                        " ) "}
                    </Form.Text>
                  </Col>
                  <Col md={6} className="mb-2 float-end">
                    <i
                      className="bi bi-plus-circle-fill float-end"
                      style={customStyles.iconPlus}
                      onClick={() => {
                        formik.setFieldValue("early_payment_dates", [
                          ...values.early_payment_dates,
                          {
                            amount: "",
                            end_date: "",
                            edited: false,
                            bank_account_id: "",
                          },
                        ]);
                      }}
                    />
                    <span className="fw-light float-end mt-1">
                      {t("products:modalSubconcept.addChronologicalDates")}
                    </span>
                  </Col>
                </Row>
                <Row style={customStyles.prepaidContainer}>
                  {values.early_payment_dates.map((earlyPayment, index) => (
                    <>
                      <Col
                        key={index}
                        md={sapIsActive ? 3 : 5}
                        className="mb-2"
                      >
                        <FieldInput
                          label={t("products:modalSubconcept.amountPayable")}
                          name={"early_payment_dates[" + index + "].amount"}
                          type="text"
                          placeholder="$"
                          value={earlyPayment.amount}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "early_payment_dates[" + index + "].amount",
                              event.target.value
                            );
                          }}
                          onKeyPress={(e) => {
                            if (
                              (e.which < 48 || e.which > 57) &&
                              e.which !== 8 &&
                              e.which !== 46 &&
                              e.which !== 44
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Col>
                      <Col md={sapIsActive ? 4 : 7} className="mb-2">
                        <Row>
                          <Col md={sapIsActive ? 12 : 11}>
                            <DatePicker
                              label={t("products:modalSubconcept.deadline")}
                              placeholderText="DD/MM/YYYY"
                              name={
                                "early_payment_dates[" + index + "].end_date"
                              }
                              minDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              {...Locallanguage}
                              cleanButton={true}
                              value={earlyPayment.end_date}
                              onSelect={(date) => {
                                formik.setFieldValue(
                                  "early_payment_dates[" + index + "].end_date",
                                  date
                                );
                              }}
                              formGroupClassName="subconcept-modal mb-3"
                            />
                          </Col>
                          {!sapIsActive && (
                            <Col md={1}>
                              <i
                                className="bi bi-trash-fill float-end mt-4"
                                style={customStyles.iconTrash}
                                onClick={() => {
                                  let newEarlyPaymentDates =
                                    values.early_payment_dates;
                                  newEarlyPaymentDates.splice(index, 1);
                                  formik.setFieldValue(
                                    "early_payment_dates",
                                    newEarlyPaymentDates
                                  );
                                }}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                      {sapIsActive && (
                        <Col md={5} className="mb-2">
                          <Row>
                            <Col md={11}>
                              <FieldInput
                                label={t(
                                  "products:modalSubconcept.accountingAccountID"
                                )}
                                name="sap"
                                type="text"
                                placeholder="SAP"
                                value={earlyPayment.bank_account_id}
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "early_payment_dates[" +
                                      index +
                                      "].bank_account_id",
                                    event.target.value
                                  );
                                }}
                              />
                            </Col>
                            <Col md={1}>
                              <i
                                className="bi bi-trash-fill float-end mt-4"
                                style={customStyles.iconTrash}
                                onClick={() => {
                                  let newEarlyPaymentDates =
                                    values.early_payment_dates;
                                  newEarlyPaymentDates.splice(index, 1);
                                  formik.setFieldValue(
                                    "early_payment_dates",
                                    newEarlyPaymentDates
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </>
                  ))}
                  {/* no early payments */}
                  {values.early_payment_dates.length === 0 && (
                    <Col md={12} className="mb-2">
                      {t(
                        "products:modalSubconcept.noBeforePrepaymentDeadlineDates"
                      )}
                    </Col>
                  )}
                  {}
                </Row>

                {/* Cargo moratorio */}
                <Row>
                  <Col
                    md={sapIsActive ? 4 : 6}
                    className="mb-2 subconcept-modal"
                  >
                    <FormInputLeftDropdown
                      label={t("products:modalSubconcept.lateFee")}
                      complementaryLabel={t(
                        "products:modalSubconcept.idExceedDeadline"
                      )}
                      name="penalty"
                      placeholder={
                        values.penalty_type == "flat_fee" ? "$ 0.00" : "0.00 %"
                      }
                      dropdownlabel={
                        values.penalty_type == "flat_fee" ? "$" : "%"
                      }
                      dropdownfield="penalty_type"
                      dropdownmenu={[
                        { value: "flat_fee", field: "$" },
                        { value: "percentage", field: "%" },
                      ]}
                      // onChange={(event) => { }}
                      formGroupClassName="subconcept-modal mb-3"
                    />
                  </Col>
                  <Col md={sapIsActive ? 4 : 6} className="">
                    <Form.Label
                      style={sapIsActive ? { marginBottom: "29px" } : {}}
                    >
                      {t("products:modalSubconcept.deadlineWithoutSurcharge")}
                    </Form.Label>
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      name="surcharge_end_date"
                      minDate={
                        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      {...Locallanguage}
                      cleanButton={true}
                      formGroupClassName="subconcept-modal mb-3"
                    />
                  </Col>
                  {sapIsActive && (
                    <Col md={4} className="mb-2">
                      <Form.Label style={{ marginBottom: "29px" }}>
                        {t("products:modalSubconcept.accountingAccountID")}
                      </Form.Label>
                      <FieldInput
                        name="surcharge_bank_account_id"
                        type="text"
                        placeholder="SAP"
                      />
                    </Col>
                  )}
                </Row>

                <Row className={showIvaSurcharge ? "" : "d-none"}>
                  {/**add_IVA_surcharge*/}
                  <Col md={6}>
                    <FormCheck
                      name="add_IVA_surcharge"
                      label={t("products:modalSubconcept.applyVatLateFee")}
                      type="checkbox"
                      style={{ marginTop: "37px", fontSize: "14px" }}
                      onClick={() => {
                        if (values.add_IVA_surcharge) {
                          formik.setFieldValue("iva_surcharge", "");
                        }
                      }}
                      checed={values.add_IVA_surcharge}
                    />
                  </Col>
                  {values.add_IVA_surcharge && (
                    <Col md={6}>
                      <FormSelect
                        label={t("products:modalSubconcept.ivaForLateFee")}
                        name="iva_surcharge"
                        options={[
                          {
                            value: "8",
                            label: "8 %",
                          },
                          {
                            value: "16",
                            label: "16 %",
                          },
                        ]}
                        onChange={(country) => {
                          formik.setFieldValue("iva_surcharge", country.value);
                        }}
                        value={
                          values.iva_surcharge
                            ? {
                                value: values.iva_surcharge,
                                label: values.iva_surcharge + " %",
                              }
                            : ""
                        }
                        placeholder="0.00 %"
                      />
                    </Col>
                  )}
                </Row>

                {/* recurrencia */}
                <Row className="mt-3">
                  {values.penalty && values.penalty != "" && (
                    <Col md={6} className="mb-2">
                      <FormSelect
                        label={t("products:modalSubconcept.lateFeeRecurrence")}
                        name="penalty_period"
                        options={surchargePeriods}
                        onChange={(event) => {
                          formik.setFieldValue("penalty_period", event.value);
                        }}
                        value={surchargePeriods.find(
                          (period) => period.value === values.penalty_period
                        )}
                        placeholder={t(
                          "products:modalSubconcept.lateFeeRecurrence"
                        )}
                      />
                    </Col>
                  )}
                  {/* RFC */}
                  <Col md={6} className="mb-2">
                    <Form.Label>
                      {t("products:modalSubconcept.RFCtoBill")}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <FormSelect
                      name="issuer_rfc_id"
                      options={rfcsIssuerList}
                      onChange={(event) => {
                        // setSelectCountry(event);
                        formik.setFieldValue("issuer_rfc_id", event.value);
                      }}
                      value={rfcsIssuerList.find(
                        (rfc) => rfc.value === values.issuer_rfc_id
                      )}
                      placeholder={t("products:modalSubconcept.none")}
                      showRequired={true}
                      required={true}
                    />
                  </Col>
                </Row>

                {/* notas */}
                <Row>
                  <Col md={12} className="mb-2">
                    <FieldInput
                      label={t("products:modalSubconcept.notes")}
                      name="description"
                      type="text"
                      as="textarea"
                      placeholder={t(
                        "products:modalSubconcept.notesPlaceholder"
                      )}
                      style={{ resize: "none", height: "60px" }}
                    />
                  </Col>
                </Row>
                {errors && (
                  <Row>
                    <Col md={12} className="mb-2">
                      <Alert variant="warning">{errors.description}</Alert>
                    </Col>
                  </Row>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="outline-secondary"
                  disabled={loading}
                  onClick={props.onHide}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disableSaveButton(values)}
                >
                  {loading ? (
                    <>
                      {t("global:buttons.creating")}
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="white"
                        style={{
                          width: "0.81rem",
                          height: "0.81rem",
                          marginLeft: "3px",
                          borderWidth: "0.17em",
                        }}
                      />
                    </>
                  ) : (
                    t("global:buttons.add")
                  )}
                </Button>
              </Modal.Footer>
            </FormOfFormik>
          )}
        </Formik>
      ) : (
        // lazy loading
        <div className="d-flex justify-content-center mt-5 mb-5">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </Modal>
  );
}

ModalSubconcept.propTypes = {
  productConceptId: PropTypes.string.isRequired,
  showSubconceptModal: PropTypes.bool,
  setShowSubconceptModal: PropTypes.func,
  cycleSelected: PropTypes.string,
  subconcept: PropTypes.object,
  onHide: PropTypes.func,
  setSubconceptSelected: PropTypes.func,
  subconcepts: PropTypes.array,
  conceptSelected: PropTypes.object,
};
