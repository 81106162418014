import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import * as moment from "moment";

// Import Custom Components
import ModalSaveCategory from "../../pages/payments/modals/ModalSaveCategory";
import ModalSubconcept from "./modalsProducts/ModalSubconcept";
import ModalImportProducts from "../../pages/payments/modals/ModalImportProducts";
import ModalChronologicalPayments from "../../pages/payments/modals/ModalChronologicalPayments";
import EliminateSubconcept from "./modalsProducts/EliminateSubconcept";

// Import API Services
import {
  GetCfdiUse,
  GetFullInfoCategories,
  GetIcons,
  GetObjTax,
  GetProductCodes,
  GetTaxKey,
  GetUnits,
  RemoveCategory,
  RemoveConcept,
  SearchSubconcepts,
} from "../../api/Products";
import ModalSuccess from "../global/modals/ModalSuccess";
import ModalSaveConcept from "../../pages/payments/modals/ModalSaveConcept";
import { goTo, normalizeString, thousandsFormat } from "../global/GlobalTools";
import { MainContext } from "../../App";

// Custom Styles
const customStyles = {
  baseContainer: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  card: {
    background: "#ffffff",
    border: "1px solid #ececee",
  },
  containerCard: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  circleButtonPlus: {
    cursor: "pointer",
    float: "right",
    fontSize: "17px",
    color: "var(--red-primary)",
    marginTop: "17px",
  },
  circleButtonPlusDisabled: {
    float: "right",
    fontSize: "17px",
    color: "var(--red-primary)",
    marginTop: "17px",
    opacity: "0.5",
  },
  itemContainer: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  itemStyle: {
    border: "solid 1px #969696",
    borderRadius: "0.25rem",
    height: "50px",
  },
  productCell: {
    borderRight: "1px solid gray",
    marginTop: "10px",
    height: "30px",
    paddingTop: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productHeader: {
    marginTop: "10px",
    height: "30px",
    paddingTop: "5px",
    // color: "white",
  },
  textBreak: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lazy: {
    height: "50px",
  },
};

const SubconceptsView = () => {
  const [t] = useTranslation(["reports", "global", "scholarships", "products"]);
  const { permissions } = useContext(MainContext);
  const prefix = process.env.REACT_APP_PREFIX;
  const [cycles, setCycles] = useState([]);
  const [loadingCycles, setLoadingCycles] = useState(false);
  const [cycleSelected, setCycleSelected] = useState({});
  const [showModalSaveCategory, setShowModalSaveCategory] = useState(false);
  const [icons, setIcons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subconcepts, setSubconcepts] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const [conceptSelected, setConceptSelected] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [showModalRemoveCategory, setShowModalRemoveCategory] = useState(false);
  const [isRemovingCategory, setIsRemovingCategory] = useState(false);
  const [typingCallback, setTypingCallback] = useState(null);
  const [concepts, setConcepts] = useState([]);
  const [showModalSaveConcept, setShowModalSaveConcept] = useState(false);
  const [conceptToEdit, setConceptToEdit] = useState(null);
  const [showModalRemoveConcept, setShowModalRemoveConcept] = useState(false);
  const [isRemovingConcept, setIsRemovingConcept] = useState(false);
  const [units, setUnits] = useState([]);
  const [cfdiUses, setCfdiUses] = useState([]);
  const [productCodes, setProductCodes] = useState([]);
  const [objectTaxes, setObjectTaxes] = useState([]);
  const [taxKeys, setTaxKeys] = useState([]);
  const [showSubconceptModal, setShowSubconceptModal] = useState(false);
  const [availableData, setAvailableData] = useState(false);
  const [showModalImportProducts, setShowModalImportProducts] = useState(false);
  const [showModalChronologicalPayments, setShowModalChronologicalPayments] =
    useState(false);
  const [showEliminateSubconcept, setShowEliminateSubconcept] = useState(false);

  const [subconceptSelected, setSubconceptSelected] = useState(null);
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 300
  );
  const [heightList, setHeightList] = useState(window.innerHeight - 364);
  const [heightListSubconcepts, setHeightListSubconcepts] = useState(
    window.innerHeight - 420
  );

  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingConcepts, setLoadingConcepts] = useState(true);
  const [loadingSubconcepts, setLoadingSubconcepts] = useState(true);
  const [searchingSubCallback, setSearchingSubCallback] = useState(null);
  const [subconceptsFiltered, setSubconceptsFiltered] = useState([]);

  // Monitor screen resizing
  window.addEventListener("resize", () => {
    //
    if (window.innerWidth < 1200) {
      setContainerHeight("auto");
      setHeightList("auto");
      setHeightListSubconcepts("auto");
    } else {
      setContainerHeight(window.innerHeight - 300);
      setHeightList(window.innerHeight - 364);
      setHeightListSubconcepts(window.innerHeight - 420);
    }
  });

  /* Initial Loading */
  useEffect(() => {
    getSchoolCycle();
    getIcons();
    getUnits();
    getCfdiUses();
    getObjectTax();
    getTaxKey();
    getProductCodes();
  }, []);

  useEffect(() => {
    if (cycleSelected.id) {
      searchSubconcept();
    }
  }, [cycleSelected]);

  /**
   * Description: Function to get all information about of products
   * @param {string} schoolCycleId
   * @param {string} categoryId
   * @param {string} conceptId
   */
  const getFullInfoCategories = (schoolCycleId, categoryId, conceptId) => {
    // Set lazy loadings
    if (!categoryId && !conceptId) {
      setLoadingCategories(true);
      setLoadingConcepts(true);
    } else if (categoryId != categorySelected?.id) {
      setLoadingConcepts(true);
    }
    setLoadingSubconcepts(true);

    // Create required payload
    const payload = {
      school_cycle_id: schoolCycleId,
    };
    // Validate optional payload
    if (categoryId) payload.product_category_id = categoryId;
    if (conceptId) payload.product_concept_id = conceptId;
    GetFullInfoCategories(payload)
      .then((response) => {
        if (response?.data) {
          const data = response.data || [];
          // Format data
          const categories = data.map((category, categoryPos) => {
            return {
              ...category,
              index: categoryPos,
              icon_id: category.icon?.id,
              product_concepts: category.product_concepts?.map(
                (concept, conceptPos) => {
                  return {
                    ...concept,
                    index: conceptPos,
                  };
                }
              ),
            };
          });
          // Identify the initial category and concept
          const selectedCategory = categories[0];
          const selectedConcept = selectedCategory?.product_concepts?.[0];
          // Set values
          if (!categoryId && !conceptId) {
            setCategories(categories);
            setConcepts(selectedCategory?.product_concepts || []);
            setSubconcepts(selectedConcept?.subconcepts || []);
            setCategorySelected(selectedCategory);
            setConceptSelected(selectedConcept);
          } else {
            let newConcepts = selectedCategory?.product_concepts || [];
            let newSubconcepts = selectedConcept?.subconcepts || [];
            // Checks if the selected category is the same as the selected category
            if (categorySelected.id != categoryId) {
              setCategorySelected(selectedCategory);
              setConcepts(newConcepts);
              setConceptSelected(selectedConcept);
            } else {
              // Checks if the selected concept is the same as the selected concept
              const newConcept = selectedCategory?.product_concepts?.find(
                (concept) => concept.id == conceptId
              );
              setConceptSelected(newConcept);
              newSubconcepts = newConcept?.subconcepts || [];
            }
            setSubconcepts(newSubconcepts);
          }
        }
        setAvailableData(true);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingCategories(false);
        setLoadingConcepts(false);
        setLoadingSubconcepts(false);
      });
  };

  /**
   * Description: Function to format the concept so that it can be edited
   * @param {object} concept
   */
  const formatConcept = (concept) => {
    let taxKeyId = {};
    let taxObjectId = {};
    let cfdiUseId = {};
    let productCodeId = {};
    let unitId = {};
    // Find the selected Tax Code
    if (concept.c_impuesto_id) {
      taxKeyId = taxKeys.find((obj) => obj.id == concept.c_impuesto_id) || {};
    }
    // Find the selected Tax Object Key
    if (concept.c_objeto_impuesto_id) {
      taxObjectId =
        objectTaxes.find((obj) => obj.id == concept.c_objeto_impuesto_id) || {};
    }
    // Find the selected Cfdi Use
    if (concept.cfdi_use) {
      cfdiUseId = {
        value: concept.cfdi_use.id,
        label: concept.cfdi_use.cfdi_description,
      };
    }
    // Find the selected Product Code
    if (concept.product_code) {
      productCodeId = {
        value: concept.product_code.id,
        label: concept.product_code.description,
      };
    }
    // Find the selected Unit Meassurement
    if (concept.unit) {
      unitId = {
        value: concept.unit.id,
        label: concept.unit.meassurement_unit,
      };
    }
    // Concept formatted
    const conceptToEdit = {
      ...concept,
      tax_object_id: taxObjectId,
      tax_key_id: taxKeyId,
      unit_id: unitId,
      cfdi_use_id: cfdiUseId,
      product_code_id: productCodeId,
    };

    setConceptToEdit(conceptToEdit);
    setShowModalSaveConcept(true);
  };

  /**
   * Description: Get icons for categories
   */
  const getIcons = () => {
    GetIcons()
      .then((response) => {
        if (response.data) {
          setIcons(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  /**
   * Description: Remove category
   */
  const removeCategory = () => {
    const payload = {
      id: categoryToEdit.id,
    };
    setIsRemovingCategory(true);
    RemoveCategory(payload)
      .then((response) => {
        if (response.data) {
          setShowModalRemoveCategory(false);
          setCategoryToEdit(null);
          // Remove category from list by id
          const categoriesUpdated = categories.filter(
            (category) => category.id != payload.id
          );
          setCategories(categoriesUpdated);
          // Clean category and concept selected if is removed
          if (categorySelected?.id == payload.id) {
            setCategorySelected(null);
            setConceptSelected(null);
            setConcepts([]);
            setSubconcepts([]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsRemovingCategory(false);
      });
  };

  /**
   * Description: Remove concept
   */
  const removeConcept = () => {
    const payload = {
      id: conceptToEdit.id,
    };
    setIsRemovingConcept(true);
    RemoveConcept(payload)
      .then((response) => {
        if (response.data) {
          setShowModalRemoveConcept(false);
          setConceptToEdit(null);
          // Remove concept from list by id
          const conceptsUpdated = concepts.filter(
            (concept) => concept.id != payload.id
          );
          setConcepts(conceptsUpdated);
          // Clean concept selected if is removed
          if (conceptSelected?.id == payload.id) {
            setConceptSelected(null);
            setSubconcepts([]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsRemovingConcept(false);
      });
  };

  const getSchoolCycle = () => {
    setLoadingCycles(true);
    const cyclesStorage = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles =
      cyclesStorage && Array.isArray(cyclesStorage) && cyclesStorage.length;
    if (hasCycles) {
      const cycles = cyclesStorage.map((item) => {
        return {
          ...item,
          label: item.school_cycle,
          value: item.id,
          id: item.id,
        };
      });
      const currentCycle = getCurrentCycle(cyclesStorage);
      setCycleSelected(currentCycle);
      getFullInfoCategories(currentCycle.id);
      setCycles(cycles);
      setLoadingCycles(false);
    } else {
      setTimeout(() => {
        getSchoolCycle();
      }, 1000);
    }
  };

  /**
   * Function that brings the current cycle
   * @param {array} cycles
   * @returns
   */
  const getCurrentCycle = (cycles) => {
    let currentCycle = false;
    const today = moment().unix();
    for (let cycle of cycles) {
      if (
        today > cycle.start_time &&
        today < cycle.end_time &&
        cycle.current_cycle == 1
      ) {
        currentCycle = cycle;
      }
    }
    if (!currentCycle) {
      currentCycle = cycles.find((cycle) => cycle.current_cycle === "1");
    }
    if (currentCycle) currentCycle.label = currentCycle.school_cycle;
    return currentCycle;
  };

  /**
   * Function for cycle selection by id
   * @param {Object} cycle
   */
  const selectCycle = (cycle) => {
    setCycleSelected(cycle);
    getFullInfoCategories(cycle.id);
  };

  /**
   * Description: Funcion to get units of measurement
   * @param {string} inputValue
   * @param {function} callback
   */
  const getUnits = (inputValue, callback) => {
    let delay = 300;
    if (!inputValue) {
      delay = 0;
    }
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    const typing = setTimeout(() => {
      const payload = {
        meassurement_unit: inputValue,
        limit: 20,
      };
      GetUnits(payload)
        .then((response) => {
          let units =
            response?.data && !response.data?.not_found ? response.data : [];
          units.map((unit) => {
            unit.label = unit.meassurement_unit;
            unit.value = unit.id;
            unit.field = "unit_id";
          });
          if (units?.length) {
            const noneOption = {
              label: t("products:main.noneOption"),
              id: "none",
              field: "unit_id",
            };
            units.unshift(noneOption);
          }
          if (callback) {
            callback(units);
          } else {
            setUnits(units);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    }, delay);
    setTypingCallback(typing);
  };

  /**
   * Description: Funcion to get cfdi uses
   * @param {string} inputValue
   * @param {function} callback
   */
  const getCfdiUses = (inputValue, callback) => {
    let delay = 300;
    if (!inputValue) {
      delay = 0;
    }
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    const typing = setTimeout(() => {
      if (callback) {
        const cfdiUsesFiltered = cfdiUses.filter((item) =>
          normalizeString(item?.cfdi_description)
            ?.toLowerCase()
            .includes(normalizeString(inputValue)?.toLowerCase())
        );
        callback(cfdiUsesFiltered);
      } else {
        GetCfdiUse()
          .then((response) => {
            let cfdiUses = response?.data || [];
            cfdiUses.map((cfdiUse) => {
              cfdiUse.label = cfdiUse.cfdi_description;
              cfdiUse.value = cfdiUse.id;
              cfdiUse.field = "cfdi_use_id";
            });
            if (cfdiUses?.length) {
              const noneOption = {
                label: t("products:main.noneOption"),
                id: "none",
                field: "cfdi_use_id",
              };
              cfdiUses.unshift(noneOption);
            }
            setCfdiUses(cfdiUses);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    }, delay);
    setTypingCallback(typing);
  };

  /**
   * Description: Funcion to get product codes
   * @param {string} inputValue
   * @param {function} callback
   */
  const getProductCodes = (inputValue, callback) => {
    let delay = 300;
    if (!inputValue) {
      delay = 0;
    }
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    const typing = setTimeout(() => {
      const payload = {
        description: inputValue,
        limit: 20,
      };
      GetProductCodes(payload)
        .then((response) => {
          let productCodes =
            response?.data && !response.data?.not_found ? response.data : [];
          productCodes.map((productCode) => {
            productCode.label = productCode.description;
            productCode.value = productCode.id;
            productCode.field = "product_code_id";
          });
          if (productCodes?.length) {
            const noneOption = {
              label: t("products:main.noneOption"),
              id: "none",
              field: "product_code_id",
            };
            productCodes.unshift(noneOption);
          }
          if (callback) {
            callback(productCodes);
          } else {
            setProductCodes(productCodes);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    }, delay);
    setTypingCallback(typing);
  };

  /**
   * Description: Funcion to get object taxes
   * @param {string} inputValue
   * @param {function} callback
   */
  const getObjectTax = (inputValue, callback) => {
    let delay = 300;
    if (!inputValue) {
      delay = 0;
    }
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    const typing = setTimeout(() => {
      if (callback) {
        const objectTaxesFiltered = objectTaxes.filter((item) =>
          normalizeString(item?.description)
            ?.toLowerCase()
            .includes(normalizeString(inputValue)?.toLowerCase())
        );
        callback(objectTaxesFiltered);
      } else {
        GetObjTax()
          .then((response) => {
            let objectTaxes = response?.data || [];
            objectTaxes.map((objectTax) => {
              objectTax.label = `${objectTax.c_objetoImp} - ${objectTax.description}`;
              objectTax.value = objectTax.id;
              objectTax.field = "tax_object_id";
            });
            setObjectTaxes(objectTaxes);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    }, delay);
    setTypingCallback(typing);
  };

  /**
   * Description: Funcion to get key taxes
   * @param {string} inputValue
   * @param {function} callback
   */
  const getTaxKey = (inputValue, callback) => {
    let delay = 300;
    if (!inputValue) {
      delay = 0;
    }
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    const typing = setTimeout(() => {
      if (callback) {
        const taxKeysFiltered = taxKeys.filter((item) =>
          normalizeString(item?.description)
            ?.toLowerCase()
            .includes(normalizeString(inputValue)?.toLowerCase())
        );
        callback(taxKeysFiltered);
      } else {
        GetTaxKey()
          .then((response) => {
            let taxKeys = response?.data || [];
            taxKeys.map((taxKey) => {
              taxKey.label = `${taxKey.c_impuesto} - ${taxKey.description}`;
              taxKey.value = taxKey.id;
              taxKey.field = "tax_key_id";
            });
            setTaxKeys(taxKeys);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    }, delay);
    setTypingCallback(typing);
  };

  const searchSubconcept = (inputValue, callback) => {
    if (searchingSubCallback) {
      clearTimeout(searchingSubCallback);
    }
    const typing = setTimeout(() => {
      const payload = {
        school_cycle_id: cycleSelected?.id,
        search: inputValue || "",
      };
      SearchSubconcepts(payload)
        .then((response) => {
          let subconcepts = response.data;
          subconcepts.map((subconcept) => {
            subconcept.label = subconcept.name;
            subconcept.value = subconcept.id;
          });
          setSubconceptsFiltered(subconcepts);
          callback(subconcepts);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    }, 500);

    setSearchingSubCallback(typing);
  };

  const lazyLoading = () => {
    return (
      <Row className="table-lazyLoading">
        {Array.from(Array(5).keys()).map((index) => (
          <Col md={12} key={index} className="text-center mb-2">
            <div style={customStyles.lazy}></div>
          </Col>
        ))}
      </Row>
    );
  };

  /**
   * Description: Function to select a category
   * @param {object} category
   */
  const selectCategory = (category) => {
    setCategorySelected(category);
    setConceptSelected(category.product_concepts?.[0]);
    getFullInfoCategories(
      cycleSelected.id,
      category.id,
      category.product_concepts?.[0]?.id
    );
  };

  /**
   * Description: Function to select a concept
   * @param {object} concept
   */
  const selectConcept = (concept) => {
    setConceptSelected(concept);
    getFullInfoCategories(cycleSelected.id, categorySelected.id, concept.id);
  };

  /**
   * Description: Function to select a subconcept
   * @param {object} subconcept
   * @param {boolean} [selectedFromList=false]
   */
  const selectSubconcept = (subconcept, selectedFromList = false) => {
    if (!selectedFromList) {
      const productCategoryId = subconcept.product_category_id;
      const productConceptId = subconcept.product_concept_id;
      // Check if it is a product from the current list
      const isSameCategory = productCategoryId == categorySelected?.id;
      const isSameConcept = productConceptId == conceptSelected?.id;
      const isProductCurrentList = isSameCategory && isSameConcept;
      // If it is not a product in the current list, get the data again
      if (!isProductCurrentList) {
        getFullInfoCategories(
          cycleSelected.id,
          productCategoryId,
          productConceptId
        );
      }
    }
    setSubconceptSelected(subconcept);
    setShowSubconceptModal(true);
  };

  return permissions.payments_subconcepts.access ? (
    <Container fluid style={customStyles.baseContainer}>
      {/* Header */}
      <Row className="mb-3">
        <Col xs={12} lg={12} style={{ position: "relative" }}>
          {/* Subconcept finder */}
          <div
            className="d-md-inline-block float-end narrowFinder"
            style={{ width: "180px" }}
          >
            <AsyncSelect
              loadOptions={searchSubconcept}
              placeholder={t("products:main.placeholderSearchSubconcept")}
              onChange={(student) => selectSubconcept(student)}
              onBlur={() => setSubconceptsFiltered([])}
              isClearable
              defaultOptions={subconceptsFiltered}
              value={null}
              styles={{ width: "300px" }}
              isDisabled={!availableData}
            />
          </div>

          {/* Cycle selector */}
          <Dropdown
            className="d-md-inline-block me-3 float-end"
            style={{ minWidth: "180px" }}
          >
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="outline-secondary"
              style={{ minWidth: "180px" }}
              disabled={loadingCycles}
            >
              {cycleSelected?.label || t("products:main.cyclePlaceholder")}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                minWidth: "180px",
                maxHeight: "400px",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {cycles.map((cycle) => {
                return (
                  <Dropdown.Item
                    key={cycle.id}
                    eventKey={cycle.value}
                    onClick={() => {
                      selectCycle(cycle);
                      setCategorySelected(null);
                      setConceptSelected(null);
                    }}
                  >
                    {cycle.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          {/* Chronological payments */}
          <Button
            className="float-end me-3"
            variant="outline-secondary"
            onClick={() => setShowModalChronologicalPayments(true)}
            style={{ width: "180px" }}
            disabled={
              !availableData ||
              !categories.some(
                (category) =>
                  category?.product_concepts &&
                  category.product_concepts.length > 0
              )
            }
          >
            <i className="bi bi-gear" />
            {t("products:main.chronologicalPayments")}
          </Button>
        </Col>
      </Row>
      {/* Cards */}
      <Row>
        {/* Categories card */}
        <Col xl={3} lg={6} style={{ paddingRight: "20px" }}>
          <Row>
            <Col
              md={12}
              style={{
                height: "auto",
                maxHeight: containerHeight,
                ...customStyles.card,
              }}
            >
              <Row>
                <Col md={12}>
                  {/* Categories Header */}
                  <Row>
                    <Col xxl={11} xl={10} lg={10} md={10} className="mt-3 pe-0">
                      <h5 className="mb-4 fw-bold">
                        {t("products:main.titleCategories")}
                      </h5>
                    </Col>
                    <Col xxl={1} xl={2} lg={2} md={2} className="ps-0">
                      {permissions.payments_subconcepts.create && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="addCategory">
                              {t("products:main.addCategory")}
                            </Tooltip>
                          }
                        >
                          <i
                            className="bi bi-plus-circle-fill"
                            style={
                              availableData
                                ? customStyles.circleButtonPlus
                                : customStyles.circleButtonPlusDisabled
                            }
                            onClick={() => {
                              availableData
                                ? setShowModalSaveCategory(true)
                                : null;
                            }}
                          />
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </Col>
                {/* items section */}
                <Col
                  md={12}
                  style={{
                    height: "auto",
                    maxHeight: heightList,
                    overflowY: "auto",
                  }}
                >
                  {loadingCategories ? (
                    lazyLoading()
                  ) : categories?.length > 0 ? (
                    categories.map((category) => (
                      <Row
                        key={category.id}
                        style={customStyles.itemContainer}
                        className="pb-3"
                      >
                        <Col
                          md={12}
                          style={customStyles.itemStyle}
                          className={
                            categorySelected?.id == category.id
                              ? "subconceptItemsActive"
                              : "subconceptItems"
                          }
                        >
                          <Row>
                            <Col
                              xxl={2}
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              className="ps-0 c-pointer"
                              onClick={() => selectCategory(category)}
                            >
                              <i
                                className={"fal " + category.icon?.name}
                                style={{
                                  float: "right",
                                  fontSize: "20px",
                                  marginTop: "15px",
                                }}
                              />
                            </Col>
                            <Col
                              xxl={6}
                              xl={5}
                              lg={5}
                              md={6}
                              sm={6}
                              xs={6}
                              className="pt-3 px-0 c-pointer"
                              style={customStyles.textBreak}
                              onClick={() => selectCategory(category)}
                            >
                              {category.name}
                            </Col>
                            <Col
                              xxl={4}
                              xl={5}
                              lg={5}
                              md={4}
                              sm={4}
                              xs={4}
                              className="ps-0 pe-1"
                            >
                              <i
                                className="bi bi-chevron-right ms-1"
                                onClick={() => selectCategory(category)}
                                style={{
                                  cursor: "pointer",
                                  float: "right",
                                  fontSize: "20px",
                                  marginTop: "9px",
                                }}
                              />
                              {permissions.payments_subconcepts.delete && (
                                <i
                                  className="bi bi-trash-fill ms-1 subconceptEditIcons"
                                  onClick={() => {
                                    setCategoryToEdit(category);
                                    setShowModalRemoveCategory(true);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    float: "right",
                                    fontSize: "20px",
                                    marginTop: "9px",
                                  }}
                                />
                              )}
                              {permissions.payments_subconcepts.edit && (
                                <i
                                  className="bi bi-pencil-fill ms-1 subconceptEditIcons"
                                  onClick={() => {
                                    setCategoryToEdit(category);
                                    setShowModalSaveCategory(true);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    float: "right",
                                    fontSize: "20px",
                                    marginTop: "9px",
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Col md={12} className="text-center  py-5">
                        <h6 className="">
                          {t("products:main.noCategoryMessage")}
                        </h6>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Concepts card */}
        <Col
          xl={3}
          lg={6}
          className="mt-md-3 mt-lg-0"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <Row>
            <Col
              md={12}
              style={{
                height: "auto",
                maxHeight: containerHeight,
                ...customStyles.card,
              }}
            >
              <Row>
                {/* Concepts Header */}
                <Col md={12}>
                  <Row>
                    <Col
                      xxl={11}
                      xl={10}
                      lg={10}
                      md={11}
                      sm={11}
                      xs={11}
                      className="mt-3"
                    >
                      <h5 className="mb-4 fw-bold">
                        {t("products:main.titleConcepts")}
                      </h5>
                    </Col>
                    <Col
                      xxl={1}
                      xl={2}
                      lg={2}
                      md={1}
                      sm={1}
                      xs={1}
                      className="ps-0"
                    >
                      {permissions.payments_subconcepts.create && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="addConcept">
                              {t("products:main.addConcept")}
                            </Tooltip>
                          }
                        >
                          <i
                            className="bi bi-plus-circle-fill"
                            onClick={() => {
                              categorySelected && !loadingConcepts
                                ? setShowModalSaveConcept(true)
                                : null;
                            }}
                            style={
                              categorySelected && !loadingConcepts
                                ? customStyles.circleButtonPlus
                                : customStyles.circleButtonPlusDisabled
                            }
                          />
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </Col>
                {/* items section */}
                <Col
                  md={12}
                  style={{
                    height: "auto",
                    maxHeight: heightList,
                    overflowY: "auto",
                  }}
                >
                  {loadingConcepts
                    ? lazyLoading()
                    : concepts?.length > 0
                    ? concepts.map((item) => (
                        <Row
                          key={item.id}
                          style={customStyles.itemContainer}
                          className="pb-3"
                        >
                          <Col
                            md={12}
                            style={customStyles.itemStyle}
                            className={
                              conceptSelected?.id == item.id
                                ? "subconceptItemsActive"
                                : "subconceptItems"
                            }
                          >
                            <Row>
                              <Col
                                xxl={8}
                                xl={7}
                                lg={7}
                                md={8}
                                sm={8}
                                xs={8}
                                className="pt-3 c-pointer"
                                style={customStyles.textBreak}
                                onClick={() => selectConcept(item)}
                              >
                                {item.name}
                              </Col>

                              <Col
                                xxl={4}
                                xl={5}
                                lg={5}
                                md={4}
                                sm={4}
                                xs={4}
                                className="ps-0 pe-1"
                              >
                                <i
                                  className="bi bi-chevron-right ms-1"
                                  style={{
                                    cursor: "pointer",
                                    float: "right",
                                    fontSize: "20px",
                                    marginTop: "9px",
                                  }}
                                  onClick={() => selectConcept(item)}
                                />
                                {permissions.payments_subconcepts.delete && (
                                  <i
                                    className="bi bi-trash-fill ms-1 subconceptEditIcons"
                                    onClick={() => {
                                      setConceptToEdit(item);
                                      setShowModalRemoveConcept(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                      fontSize: "20px",
                                      marginTop: "9px",
                                    }}
                                  />
                                )}
                                {permissions.payments_subconcepts.edit && (
                                  <i
                                    className="bi bi-pencil-fill ms-1 subconceptEditIcons"
                                    onClick={() => {
                                      formatConcept(item);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                      fontSize: "20px",
                                      marginTop: "9px",
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))
                    : categories?.length > 0 && (
                        <Row>
                          <Col md={12} className="text-center  py-5">
                            <h6 className="">
                              {t("products:main.noConceptMessage")}
                            </h6>
                          </Col>
                        </Row>
                      )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Subconcepts card */}
        <Col
          xl={6}
          lg={12}
          style={{ paddingLeft: "20px", paddingRight: "24px" }}
          className="mt-md-3 mt-lg-3 mt-xl-0"
        >
          <Row>
            <Col
              md={12}
              style={{
                height: "auto",
                maxHeight: containerHeight,
                ...customStyles.card,
              }}
            >
              <Row>
                {/* Subconcepts Header */}
                <Col md={12}>
                  <Row>
                    <Col style={{ minWidth: "95%" }} className="mt-3">
                      <h5
                        className="mb-4 fw-bold d-inline-block"
                        style={{ width: "" }}
                      >
                        {t("products:main.titleSubconcepts")}
                      </h5>
                      <Button
                        variant="outline-secondary"
                        onClick={() => setShowModalImportProducts(true)}
                        className="float-end px-4"
                        disabled={!conceptSelected}
                      >
                        {t("products:main.importSubconepts")}
                      </Button>
                    </Col>

                    <Col style={{ minWidth: "5%" }} className="mt-3">
                      {permissions.payments_subconcepts.create && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="addSubconcept">
                              {t("products:main.addSubconcept")}
                            </Tooltip>
                          }
                        >
                          <i
                            className="bi bi-plus-circle-fill float-end"
                            style={
                              conceptSelected && !loadingSubconcepts
                                ? {
                                    ...customStyles.circleButtonPlus,
                                    marginTop: "0px",
                                  }
                                : {
                                    ...customStyles.circleButtonPlusDisabled,
                                    marginTop: "0px",
                                  }
                            }
                            onClick={() => {
                              conceptSelected && !loadingSubconcepts
                                ? setShowSubconceptModal(true)
                                : null;
                            }}
                          />
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>

                  {/* Header Table */}
                  <Row
                    style={customStyles.itemContainer}
                    className="productItem pb-3"
                  >
                    <Col style={{ minWidth: "95%" }}>
                      <Row>
                        <Col md={3} style={customStyles.productHeader}>
                          <h6>
                            <b>{t("products:tableSubconcepts.name")}</b>
                          </h6>
                        </Col>
                        <Col md={2} style={customStyles.productHeader}>
                          <h6>
                            <b>{t("products:tableSubconcepts.sku")}</b>
                          </h6>
                        </Col>
                        <Col md={2} style={customStyles.productHeader}>
                          <h6>
                            <b>{t("products:tableSubconcepts.category")}</b>
                          </h6>
                        </Col>
                        <Col md={3} style={customStyles.productHeader}>
                          <h6 className="text-body-secondary">
                            <b>{t("products:tableSubconcepts.description")}</b>
                          </h6>
                        </Col>
                        <Col md={2} style={customStyles.productHeader}>
                          <h6>
                            <b>{t("products:tableSubconcepts.amount")}</b>
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col style={{ padding: "0px", minWidth: "5%" }}></Col>
                  </Row>
                </Col>
                {/* items section */}
                <Col
                  md={12}
                  style={{
                    height: "auto",
                    maxHeight: heightListSubconcepts,
                    overflowY: "auto",
                  }}
                >
                  {loadingSubconcepts
                    ? lazyLoading()
                    : subconcepts?.length > 0
                    ? subconcepts.map((item) => (
                        <Row
                          key={item.id}
                          style={customStyles.itemContainer}
                          className="productItem pb-3"
                        >
                          <Col
                            style={{
                              ...customStyles.itemStyle,
                              minWidth: "95%",
                            }}
                            role="button"
                            onClick={() => selectSubconcept(item, true)}
                          >
                            <Row>
                              <Col md={3} style={customStyles.productCell}>
                                {item.name}
                              </Col>
                              <Col md={2} style={customStyles.productCell}>
                                {item.sku}
                              </Col>
                              <Col md={2} style={customStyles.productCell}>
                                {categorySelected?.name}
                              </Col>
                              <Col md={3} style={customStyles.productCell}>
                                {item.description}
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  ...customStyles.productCell,
                                  borderRight: "none",
                                }}
                              >
                                $ {thousandsFormat(item.price)}
                              </Col>
                            </Row>
                          </Col>
                          {/* subconceptEditIcons */}
                          <Col style={{ padding: "0px", minWidth: "5%" }}>
                            {permissions.payments_subconcepts.delete && (
                              <i
                                className="bi bi-trash-fill subconceptEditIcons"
                                style={{
                                  cursor: "pointer",
                                  float: "right",
                                  fontSize: "20px",
                                  marginTop: "9px",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setSubconceptSelected(item);
                                  setShowEliminateSubconcept(true);
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      ))
                    : categories?.length > 0 &&
                      concepts?.length > 0 && (
                        <Row>
                          <Col md={12} className="text-center px-5 py-5">
                            <h6 className="">
                              {t("products:main.noSubconceptMessage")}
                            </h6>
                          </Col>
                        </Row>
                      )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Modals */}
      {showModalSaveCategory && (
        <ModalSaveCategory
          show={showModalSaveCategory}
          onHide={() => {
            setShowModalSaveCategory(false);
            setCategoryToEdit(null);
          }}
          schoolCycle={cycleSelected}
          icons={icons}
          categoryToEdit={categoryToEdit}
          updateCategories={setCategories}
          updateCategorySelected={setCategorySelected}
        />
      )}
      {showModalRemoveCategory && (
        <ModalSuccess
          showModalSuccess={showModalRemoveCategory}
          title={t("products:modalRemoveCategory.deleteCategory")}
          message={t("products:modalRemoveCategory.deleteCategoryDescription")}
          fnCancelButton={() => {
            setShowModalRemoveCategory(false);
            setCategoryToEdit(null);
          }}
          txtBtnAccept={t("global:buttons.eliminate")}
          fnAcceptButton={removeCategory}
          loading={isRemovingCategory}
          textLoading={t("global:buttons.eliminating")}
        />
      )}
      {showModalSaveConcept && (
        <ModalSaveConcept
          show={showModalSaveConcept}
          onHide={() => {
            setShowModalSaveConcept(false);
            setConceptToEdit(null);
          }}
          schoolCycle={cycleSelected}
          conceptToEdit={conceptToEdit}
          updateConcepts={setConcepts}
          categoryIdSelected={categorySelected?.id}
          getUnits={getUnits}
          units={units}
          getCfdiUses={getCfdiUses}
          cfdiUses={cfdiUses}
          getProductCodes={getProductCodes}
          productCodes={productCodes}
          getObjectTax={getObjectTax}
          objectTaxes={objectTaxes}
          getTaxKey={getTaxKey}
          taxKeys={taxKeys}
          updateConceptSelected={setConceptSelected}
        />
      )}
      {showModalRemoveConcept && (
        <ModalSuccess
          showModalSuccess={showModalRemoveConcept}
          title={t("products:modalRemoveConcept.deleteConcept")}
          message={t("products:modalRemoveConcept.deleteConceptDescription")}
          fnCancelButton={() => {
            setShowModalRemoveConcept(false);
            setConceptToEdit(null);
          }}
          txtBtnAccept={t("global:buttons.eliminate")}
          fnAcceptButton={removeConcept}
          loading={isRemovingConcept}
          textLoading={t("global:buttons.eliminating")}
        />
      )}
      {showSubconceptModal && (
        <ModalSubconcept
          showSubconceptModal={showSubconceptModal}
          setShowSubconceptModal={setShowSubconceptModal}
          cycleSelected={cycleSelected}
          subconcept={subconceptSelected}
          productConceptId={conceptSelected.id}
          onHide={() => {
            setShowSubconceptModal(false);
            setSubconceptSelected(null);
          }}
          setSubconceptSelected={setSubconceptSelected}
          subconcepts={subconcepts}
          conceptSelected={conceptSelected}
        />
      )}
      {showModalImportProducts && (
        <ModalImportProducts
          show={showModalImportProducts}
          onHide={() => setShowModalImportProducts(false)}
          schoolCycle={cycleSelected}
          currentSubconcepts={subconcepts}
          updateSubconcepts={setSubconcepts}
        />
      )}
      {showModalChronologicalPayments && (
        <ModalChronologicalPayments
          show={showModalChronologicalPayments}
          onHide={() => setShowModalChronologicalPayments(false)}
          updateCategories={setCategories}
          categories={categories}
        />
      )}
      {showEliminateSubconcept && (
        <EliminateSubconcept
          showEliminateSubconcept={showEliminateSubconcept}
          setShowEliminateSubconcept={setShowEliminateSubconcept}
          subconceptSelected={subconceptSelected}
          setSubconceptSelected={setSubconceptSelected}
          subconcepts={subconcepts}
        />
      )}
    </Container>
  ) : (
    goTo("/dashboard")
  );
};

export default SubconceptsView;
