import axios from "axios";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";

let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Description: Get icons of font awesome pro 5.11.2
 * @param {string} token
 * @return {JSON object} Response from API
 */
export const GetIcons = async (token) => {
  const data = {
    token: token || localStorage.getItem(`cmToken${prefix}`),
  };

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/categories/icons`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Save category, create new or update existing
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const SaveCategory = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = data.id ? "put" : "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_categories`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Remove a category
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const RemoveCategory = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_categories`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get product codes
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetProductCodes = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/cfdi_catalogs/product_codes`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get units of measurement
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetUnits = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/cfdi_catalogs/units`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get Cfdi Uses
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetCfdiUse = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/cfdi_catalogs/cfdi_use`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get Object Taxes
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetObjTax = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/sat_tax_codes/catalogoObjImp`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get Key Taxes
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetTaxKey = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/sat_tax_codes/catalogoImpuesto`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get a unique code for the concept
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetGenerateCode = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_concepts/code`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Save concept, create new or update existing
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const SaveConcept = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = data.id ? "put" : "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_concepts`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Remove a concept
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const RemoveConcept = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_concepts`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Download Import Subconcepts Tesmplate
 * @return {CSV} template
 */
export const GetCsvSubconceptsTemplate = async () => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/assets/csv/subconceptos.csv`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Import subconcepts
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const ImportProducts = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "multipart/form-data",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/products/import`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get a unique code for the concept
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetRfcIssuer = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations/rfc_issuer`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Save concepts with FIFO
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const ConceptFifoPayments = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_concepts/fifo_payments`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get a unique code for the concept
 * @param {obj} data
 * @returns
 */
export const GenerateSku = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/products/sku`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: get all information of a product
 * @param {obj} data
 * @returns
 */
export const GetSubconcept = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/products`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Save subconcept, create new or update existing
 * @param {obj} data
 * @returns
 */
export const SaveSubconcept = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = data.id ? "put" : "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/products`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const GetFullInfoCategories = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_categories/with_concepts`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Search for subconcepts by name
 * @param {obj} data
 * @returns
 */
export const SearchSubconcepts = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/products/search`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Delete subconcept
 * @param {*} data
 * @returns
 */
export const DeleteSubconcept = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/products`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
