/**
 *       **** Readme ****
 *
 * Component: <DatePicker />
 * Purpose: Show the calendar.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React from "react";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage, useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
//Date time picker

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const { params } = props;
  const { onChange = (val) => setFieldValue(field.name, val) } = params;
  const [field, meta] = useField(params);
  let classInputDAteView = "well";
  if (params.monthView) {
    classInputDAteView += " reactDataPicker-monthView";
  }
  //styles for selected day
  const styleSelectedDay = (date) => {
    if (field.value && date.getTime() === field.value.getTime()) {
      return "selected-day";
    } else if (
      !field.value &&
      moment(date).format("MM-DD-YYYY") ==
        moment(new Date()).format("MM-DD-YYYY")
    ) {
      return "selected-day";
    } else {
      return "";
    }
  };
  return (
    <DateView
      dayClassName={(date) => styleSelectedDay(date)}
      {...field}
      {...params}
      className={
        meta.touched && meta.error ? "errorValidation" : classInputDAteView
      }
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => onChange(val)}
    />
  );
};
DatePickerField.propTypes = {
  params: PropTypes.object,
  onChange: PropTypes.func,
  monthView: PropTypes.bool,
};

export function DatePicker({ label, ...props }) {
  const [t] = useTranslation(["global"]);
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <Form.Group className={props.formGroupClassName  || "mb-3"} controlId="form-datepiker">
      {label && (
        <Form.Label
          data-text={t("optional")}
          htmlFor={props.id || props.name}
          className={
            props.required && props.showRequired
              ? "required"
              : !props.required && props.showRequired === false
              ? "optional"
              : ""
          }
        >
          {label}
        </Form.Label>
      )}
      {props.cleanButton ? (
        <InputGroup>
          <span style={{ width: "87%" }}>
            <DatePickerField params={props} />
          </span>
          <span
            className="input-group-addon"
            style={{ width: "13%" }}
            onClick={() => {
              setFieldValue(field.name, null);
            }}
          >
            <i style={{ marginTop: "3px" }} className="fal fa-times-circle"></i>
          </span>
        </InputGroup>
      ) : (
        <DatePickerField params={props} />
      )}
      <ErrorMessage component="div" name={props.name} className="error" />
    </Form.Group>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  cleanButton: PropTypes.bool,
  onChange: PropTypes.func,
  formGroupClassName: PropTypes.string,
};
