import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

// Import API Services
import { GetGenerateCode, SaveConcept } from "../../../api/Products";

// Import Components
import ModalSuccess from "../../../components/global/modals/ModalSuccess";
import TextLoading from "../../../components/global/TextLoading";

// Set custom Styles
const customStyles = {
  biQuestion: {
    cursor: "pointer",
    color: "#EA2C54",
    marginLeft: ".5rem",
  },
};

export default function ModalSaveConcept(props) {
  // Hooks
  const [t] = useTranslation(["products", "global"]);
  const [concept, setConcept] = useState(
    props.conceptToEdit || {
      name: "",
      code: "",
    }
  );
  const [activeBtn, setActiveBtn] = useState(!!props.conceptToEdit);
  const [showModal, setShowModal] = useState(props.show);
  const [showModalSuccess, setShowSuccessModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [typingCallback, setTypingCallback] = useState(null);
  const [isLoadingSKU, setIsLoadingSKU] = useState(false);
  const [showWarningBilling, setShowWarningBilling] = useState(false);

  /* Initial Loading */
  useEffect(() => {
    if (!props.conceptToEdit) {
      setConcept((prev) => {
        return {
          ...prev,
          tax_object_id: props.objectTaxes?.[0],
          tax_key_id: props.taxKeys?.[1],
        };
      });
    }
    return () => {};
  }, []);

  /* Validate Concept */
  useEffect(() => {
    validateForm();
    return () => {};
  }, [concept]);

  /**
   * Validate if the form is ready
   */
  const validateForm = () => {
    let isValid = true;
    let showWarning = false;

    // Deconstruct specific properties
    const { unit_id, cfdi_use_id, product_code_id } = concept;

    for (const property in concept) {
      let result = true;

      if (["unit_id", "cfdi_use_id", "product_code_id"].includes(property)) {
        const isAnyEmpty = !unit_id || !cfdi_use_id || !product_code_id;
        const isAllEmpty = !unit_id && !cfdi_use_id && !product_code_id;

        result = isAllEmpty || concept[property] !== "";
        showWarning = isAnyEmpty && !isAllEmpty;
      } else {
        result = concept[property] !== "";
      }

      // Update `isValid` by accumulating the result
      isValid = isValid && result;
    }

    setShowWarningBilling(showWarning);
    setActiveBtn(isValid);
  };

  /**
   * Description: Function to change concept values
   * @param {object} event
   */
  const changeValues = (event) => {
    let conceptUpdated = { ...concept };
    // Update the concept
    if (event.target) {
      const field = event.target.id;
      const value = event.target.value;
      conceptUpdated[field] = value;
      if (field === "name" || field === "code") {
        generateCode(field, value);
      }
      if (field === "add_IVA_subconcept") {
        conceptUpdated[field] = event.target.checked ? 1 : 0;
      }
    } else {
      const field = event.field;
      if (field === "cfdi_use_id" && event.cfdi_use_code != "D10") {
        conceptUpdated.add_IVA_subconcept = 0;
      }
      conceptUpdated[field] =
        event.id === "none" ? "" : { value: event.id, label: event.label };
    }
    setConcept(conceptUpdated);
  };

  /**
   * Description: Function to generate unique code
   * @param {string} field
   * @param {sting} value
   */
  const generateCode = (field, value) => {
    if (value) {
      if (typingCallback) {
        clearTimeout(typingCallback);
      }
      const typing = setTimeout(() => {
        const payload = {
          [field]: value,
          school_cycle_id: props.schoolCycle?.id,
        };
        if (field === "name") {
          setIsLoadingSKU(true);
        }
        GetGenerateCode(payload)
          .then((response) => {
            if (response.data) {
              setConcept((prev) => {
                return {
                  ...prev,
                  code: response.data.code,
                };
              });
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            if (field === "name") {
              setIsLoadingSKU(false);
            }
          });
      }, 300);
      setTypingCallback(typing);
    }
  };

  /**
   * Description: Function to save a concept
   */
  const saveConcept = () => {
    const payload = {
      id: concept.id,
      product_category_id: props.categoryIdSelected,
      product_code_id: concept.product_code_id?.value,
      unit_id: concept.unit_id?.value,
      cfdi_use_id: concept.cfdi_use_id?.value,
      name: concept.name?.toUpperCase(),
      code: concept.code,
      school_cycle_id: props.schoolCycle?.id,
      c_impuesto_id: concept.tax_key_id?.value,
      c_objeto_impuesto_id: concept.tax_object_id?.value,
      add_IVA_subconcept: concept.add_IVA_subconcept || 0,
    };
    setIsSaving(true);
    SaveConcept(payload)
      .then((response) => {
        if (response.data) {
          setShowModal(false);
          setShowSuccessModal(true);
          // Update the concept, if it exists else add it
          props.updateConcepts((prev) => {
            let concepts = [...prev];
            const concept = response.data;
            if (payload.id) {
              const index = concepts.findIndex((c) => c.id == concept.id);
              concepts[index] = concept;
            } else {
              concept.index = concepts.length;
              concepts.push(concept);
            }
            props.updateConceptSelected(concept);
            return concepts;
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      {showModal && (
        <Modal show={showModal} onHide={props.onHide} backdrop={"static"}>
          <Modal.Header>
            <Modal.Title>
              {t(
                concept.id
                  ? "modalSaveConcept.editConcept"
                  : "modalSaveConcept.addConcept"
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="required">
                    {t("modalSaveConcept.conceptName")}
                  </Form.Label>
                  <Form.Control
                    id="name"
                    type="text"
                    placeholder={t("modalSaveConcept.conceptNamePlaceholder")}
                    value={concept.name}
                    onChange={changeValues}
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="required">
                    {t("modalSaveConcept.conceptKey")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      id="code"
                      type="text"
                      className={isLoadingSKU ? "" : "d-block"}
                      placeholder={t(
                        isLoadingSKU
                          ? "modalSaveConcept.generatingKey"
                          : "modalSaveConcept.conceptKeyPlaceholder"
                      )}
                      value={isLoadingSKU ? "" : concept.code}
                      onChange={changeValues}
                      disabled={isLoadingSKU}
                    />
                    {isLoadingSKU && (
                      <InputGroup.Text>
                        <span className="fas fa-circle-notch fa-spin" />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5 className="mt-3 mb-3 fw-bold">
                  {t("modalSaveConcept.dataBilling")}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="button-tooltip">
                        <p className="mt-1 mb-1">
                          {t("modalSaveConcept.dataBillingTooltip")}
                        </p>
                      </Tooltip>
                    }
                  >
                    <span
                      className="bi bi-question-circle-fill"
                      aria-hidden="true"
                      style={customStyles.biQuestion}
                    />
                  </OverlayTrigger>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("modalSaveConcept.unit")}</Form.Label>
                  <AsyncSelect
                    id="unit_id"
                    loadOptions={props.getUnits}
                    placeholder={t("modalSaveConcept.unitPlaceholder")}
                    onChange={changeValues}
                    defaultOptions={props.units}
                    value={concept.unit_id}
                    noOptionsMessage={() => t("global:select.noMatches")}
                    loadingMessage={() => t("global:select.searching")}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("modalSaveConcept.useCFDI")}
                    {concept.cfdi_use_id?.value == "21" &&
                      concept.add_IVA_subconcept != "1" && (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="whitoutVatTooltip">
                              <p className="mt-1 mb-1">
                                {t("modalSaveConcept.useCFDIWhithoutVat")}
                              </p>
                            </Tooltip>
                          }
                        >
                          <span
                            className="bi bi-question-circle-fill"
                            aria-hidden="true"
                            style={customStyles.biQuestion}
                          />
                        </OverlayTrigger>
                      )}
                  </Form.Label>
                  <AsyncSelect
                    id="cfdi_use_id"
                    loadOptions={props.getCfdiUses}
                    placeholder={t("modalSaveConcept.useCFDIPlaceholder")}
                    onChange={changeValues}
                    defaultOptions={props.cfdiUses}
                    value={concept.cfdi_use_id}
                    noOptionsMessage={() => t("global:select.noMatches")}
                    loadingMessage={() => t("global:select.searching")}
                  />
                </Form.Group>
              </Col>
            </Row>
            {concept.cfdi_use_id?.value == "21" && (
              <Row>
                <Col md={6}>
                  <Form.Check
                    id={"add_IVA_subconcept"}
                    onChange={(e) => changeValues(e)}
                    className="mb-3"
                    checked={concept.add_IVA_subconcept == "1"}
                    label={t("modalSaveConcept.checkboxShowVatField")}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("modalSaveConcept.productServiceKey")}
                  </Form.Label>
                  <AsyncSelect
                    id="product_code_id"
                    loadOptions={props.getProductCodes}
                    placeholder={t(
                      "modalSaveConcept.productServiceKeyPlaceholder"
                    )}
                    onChange={changeValues}
                    defaultOptions={props.productCodes}
                    value={concept.product_code_id}
                    noOptionsMessage={() => t("global:select.noMatches")}
                    loadingMessage={() => t("global:select.searching")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("modalSaveConcept.taxObjectKey")}</Form.Label>
                  <AsyncSelect
                    id="tax_object_id"
                    loadOptions={props.getObjectTax}
                    placeholder={t("modalSaveConcept.taxObjectKeyPlaceholder")}
                    onChange={changeValues}
                    defaultOptions={props.objectTaxes}
                    value={concept.tax_object_id}
                    isDisabled={concept.product_code_id?.value == "51828"}
                    noOptionsMessage={() => t("global:select.noMatches")}
                    loadingMessage={() => t("global:select.searching")}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("modalSaveConcept.taxKey")}
                    {concept.cfdi_use_id?.value == "21" && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="whitoutVatTooltip">
                            <p className="mt-1 mb-1">
                              {t("modalSaveConcept.useCFDIWhithoutVat")}
                            </p>
                          </Tooltip>
                        }
                      >
                        <span
                          className="bi bi-question-circle-fill"
                          aria-hidden="true"
                          style={customStyles.biQuestion}
                        />
                      </OverlayTrigger>
                    )}
                  </Form.Label>
                  <AsyncSelect
                    id="tax_key_id"
                    loadOptions={props.getTaxKey}
                    placeholder={t("modalSaveConcept.taxKeyPlaceholder")}
                    onChange={changeValues}
                    defaultOptions={props.taxKeys}
                    value={concept.tax_key_id}
                    isDisabled={concept.product_code_id?.value == "51828"}
                    noOptionsMessage={() => t("global:select.noMatches")}
                    loadingMessage={() => t("global:select.searching")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="text-danger mb-3">
                  {t("modalSaveConcept.labelInformationKeys")}
                </p>
              </Col>
            </Row>
            {showWarningBilling && (
              <Row>
                <Col md={12}>
                  <Alert variant="warning">
                    {t("modalSaveConcept.incompleteBillingData")}
                  </Alert>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                variant="secondary"
                onClick={props.onHide}
                disabled={isSaving || isLoadingSKU}
              >
                {t("global:buttons.cancel")}
              </Button>

              <Button
                variant="primary"
                onClick={saveConcept}
                disabled={!activeBtn || isSaving || isLoadingSKU}
              >
                {isSaving ? (
                  <TextLoading
                    text={t("global:buttons.saving")}
                    variant="light"
                  />
                ) : (
                  t("global:buttons.save")
                )}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/** Modal Success */}
      {showModalSuccess && (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t(
            concept.id ? "main.changesSaved" : "modalSaveConcept.conceptAdded"
          )}
          message={t(
            concept.id
              ? "main.changesSavedSuccess"
              : "modalSaveConcept.conceptAddedSuccess"
          )}
          fnAcceptButton={props.onHide}
        />
      )}
    </>
  );
}

ModalSaveConcept.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  schoolCycle: PropTypes.object.isRequired,
  conceptToEdit: PropTypes.object,
  updateConcepts: PropTypes.func.isRequired,
  categoryIdSelected: PropTypes.string.isRequired,
  getUnits: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  getCfdiUses: PropTypes.func.isRequired,
  cfdiUses: PropTypes.array.isRequired,
  getProductCodes: PropTypes.func.isRequired,
  productCodes: PropTypes.array.isRequired,
  getObjectTax: PropTypes.func.isRequired,
  objectTaxes: PropTypes.array.isRequired,
  getTaxKey: PropTypes.func.isRequired,
  taxKeys: PropTypes.array.isRequired,
  incompleteBillingData: PropTypes.bool,
  updateConceptSelected: PropTypes.func.isRequired,
};
