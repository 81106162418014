import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { DeleteSubconcept } from "../../../api/Products";

export default function EliminateSubconcept(props) {
  const [t] = useTranslation(["global", "products"]);
  const [loading, setLoading] = useState(false);

  const deleteSubconcept = () => {
    setLoading(true);
    const payload = {
      id: props.subconceptSelected.id,
    };

    DeleteSubconcept(payload).then(() => {
      // Find the position of the subitem to be deleted
      const index = props.subconcepts.findIndex(
        (subconcept) => subconcept.id === props.subconceptSelected.id
      );
      // Remove the subitem from the list
      props.subconcepts.splice(index, 1);
      // Update the list of subconcepts
      props.setSubconceptSelected({});
      setLoading(false);
      props.setShowEliminateSubconcept(false);
    });
  };

  return (
    <Modal
      show={props.showEliminateSubconcept}
      aria-labelledby="contained-modal-title-lg"
      onHide={() => props.setShowEliminateSubconcept(false)}
      backdrop={"static"}
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("products:modalRemoveSubconcept.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className=" mb-2">
            {t("products:modalRemoveSubconcept.menssage")}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          disabled={loading}
          onClick={() => props.setShowEliminateSubconcept(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          onClick={deleteSubconcept}
        >
          {loading ? (
            <>
              {t("global:buttons.eliminating")}
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="white"
                style={{
                  width: "0.81rem",
                  height: "0.81rem",
                  marginLeft: "3px",
                  borderWidth: "0.17em",
                }}
              />
            </>
          ) : (
            t("global:buttons.add")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EliminateSubconcept.propTypes = {
  showEliminateSubconcept: PropTypes.bool,
  setShowEliminateSubconcept: PropTypes.func,
  subconceptSelected: PropTypes.object,
  setSubconceptSelected: PropTypes.func,
  subconcepts: PropTypes.array,
};
